import React, { useEffect, useState } from 'react';

import { Container } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Menu } from '../../components/Menu';

import api from '../../config/configAPi';
import { Link, useLocation } from 'react-router-dom';

export const Users = () => {
  const { state } = useLocation();
  //console.log(state.mensagem);
  const [data, setData] = useState([]);

  const [status, setStatus] = useState({
    type: state ? state.type : '',
    mensagem: state ? state.mensagem : '',
  });

  const getUsers = async () => {
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    await api
      .get('/users', headers)
      .then((response) => {
        //console.log(response);
        setData(response.data.users);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'erro',
            mensagem: err.response.data.mensagem,
          });
          //console.log(err.response.data.mensagem);
        } else {
          //console.log("erro:Tente mais tarde");
          setStatus({
            type: 'erro',
            mensagem: 'Tente mais tarde',
          });
        }
      });
  };
  useEffect(() => {
    getUsers();
  }, []);

  const deleteUser = async (idUser) => {
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    await api
      .delete('/user/' + idUser, headers)
      .then((response) => {
        //console.log(response.data.mensagem);
        setStatus({
          type: 'success',
          mensagem: response.data.mensagem,
        });
        getUsers();
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'erro',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'erro',
            mensagem: 'Tente mais tarde!',
          });
        }
      });
  };

  return (
    <>
      <Menu />
      <Container>
        <br />
        <br />
        <div>
          <h2>Usuários cadastrados</h2>
          <br />
          <br />
          <Link to="/add-user">
            <Button type="button" variant="primary">
              <i className="fa-solid fa-user" style={{ fontSize: '20px' }}></i>{' '}
              Cadastrar
            </Button>
          </Link>

          {status.type === 'success' ? (
            <p style={{ color: 'green' }}>{status.mensagem}</p>
          ) : (
            ''
          )}
          {status.type === 'error' ? (
            <p style={{ color: 'red' }}>{status.mensagem}</p>
          ) : (
            ''
          )}

          <Table
            striped
            bordered
            hover
            style={{ marginTop: '15px' }}
            className="boxListar"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, i) => {
                return [
                  <tr key={i}>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.email}</td>
                    <td>
                      <Link to={'/edit-user/' + item.id}>
                        <Button type="button" variant="success">
                          <i
                            className="fa-solid fa-pen-to-square"
                            style={{ fontSize: '20px' }}
                          ></i>{' '}
                          Editar
                        </Button>
                      </Link>{' '}
                      <Link to={'#'}>
                        <Button
                          type="button"
                          variant="danger"
                          onClick={() => deleteUser(item.id)}
                        >
                          <i
                            className="fa-solid fa-trash"
                            style={{ fontSize: '20px' }}
                          ></i>{' '}
                          Excluir
                        </Button>
                      </Link>
                    </td>
                  </tr>,
                ];
              })}
            </tbody>
          </Table>
        </div>
      </Container>
    </>
  );
};
