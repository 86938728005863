import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes/routesAdm';
import { AuthProvider } from './Context/AuthContext';
import history from './services/history';

//import MenuBootstrap from './components/Menu';
//import Button  from 'react-bootstrap/Button';
//import { Container } from 'react-bootstrap';
//import Form from 'react-bootstrap/Form';


function App() {

  // const[dados, setDados] = useState({
  //   nome:'',
  //   codigo:''
  // })

  //const valorInput = e => setDados({...dados,[e.target.name]:e.target.value})

  // const cadLeiturista = e =>{
  //   e.preventDefault();
  //   console.log("Nome:" + dados.nome);
  //   console.log("codigo:" + dados.codigo);
  // }

  return (
    <div>
      {/* <MenuBootstrap /> */}
      <AuthProvider>
        <Router history={history}>
          <Routes />
        </Router>
      </AuthProvider>
      {/* <Container>
     <h1>Novo Leiturista</h1>
     <Form onSubmit={cadLeiturista}>
     <Form.Group className="mb-3" controlId="formBasicEmail">
      <Form.Label>Nome </Form.Label>
      <Form.Control type="text" name="nome" placeholder = "Nome do leiturista" onChange={valorInput} ></Form.Control><br />
      <label>Código </label>
      <Form.Control type="text" name="codigo" placeholder = "Codigo leiturista" onChange={valorInput}></Form.Control>
      </Form.Group>
      <Button variant='outline-success' type="submit" >Salvar</Button>
      </Form>    
  </Container> */}
    </div>
  );
}

export default App;
