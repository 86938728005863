import React, { useContext } from 'react';
import { Context } from '../../Context/AuthContext';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import imagem from '../../config/images';

//import { Link } from 'react-router-dom';

export const Menu = () => {
  const { handleLogout } = useContext(Context);

  const bg = process.env.NODE_ENV === 'production' ? 'primary' : 'danger';

  return (
    <>
      <Navbar bg={bg} variante="dark" expand="md">
        <Container fluid>
          <Navbar.Brand href="/Dashboard">
            <img className="ajustaLogo" alt="logoLogin" src={imagem.logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/Dashboard">Dashboard</Nav.Link>
              <Nav.Link href="/Users">Usuários</Nav.Link>
              <Nav.Link href="/Leituristas">Leituristas</Nav.Link>
              <Nav.Link href="/Arquivo">Importa Arquivo</Nav.Link>
              <Nav.Link href="/GeraArquivo">Gerar Arquivo</Nav.Link>
              <Nav.Link href="#" onClick={handleLogout}>
                Sair
              </Nav.Link>

              {/* <Link to="/Dashboard" >Dashboard</Link><br />
            <Link to="/Users" >Usuários</Link><br /> 
            <Link to="/Leituristas">Leiturista</Link><br />
            <Link to="#" onClick={handleLogout}>Sair</Link><br /> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

// import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap';

// function Menu(){
//     return(
// <Navbar bg="success" variante="dark" expand="lg">
//       <Container>
//         <Navbar.Brand href="#home">Logo Sete lagoas</Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse id="basic-navbar-nav">
//           <Nav className="me-auto">
//             <Nav.Link href="#home">Leituristas</Nav.Link>
//             <Nav.Link href="#link">Arquivo</Nav.Link>
//             <NavDropdown title="Dropdown" id="basic-nav-dropdown">
//               <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.2">
//                 Another action
//               </NavDropdown.Item>
//               <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
//               <NavDropdown.Divider />
//               <NavDropdown.Item href="#action/3.4">
//                 Separated link
//               </NavDropdown.Item>
//             </NavDropdown>
//           </Nav>
//         </Navbar.Collapse>
//       </Container>
//     </Navbar>
//     )
// }

// export default Menu;
