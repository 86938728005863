import React, { useState } from "react";
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';

import { Menu } from '../../components/Menu';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


import api from '../../config/configAPi';

export const AddLeituristas = () => {
    const [leituristas, setUser] = useState({
        name: '',
        codigo: ''
    })
    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    })

    const valueInput = e => setUser({ ...leituristas, [e.target.name]: e.target.value });

    const addLeituristas = async e => {
        e.preventDefault();
        //console.log(leituristas);

        if (!(await validate())) return;

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }

        await api.post('/leituristas', leituristas, headers)
            .then((response) => {
                //console.log(response.data.mensagem);
                setStatus({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    //console.log(err.response.data.mensagem);                
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    //console.log('Tente novamente');
                    setStatus({
                        type: 'error',
                        mensagem: 'Erro:Tente novamente'
                    });
                }
            })
    }
    const mensagemAdd = {
        type: status.type,
        mensagem: status.mensagem
    }
    // function validate(){
    //     if(!leituristas.name)return setStatus({
    //         type:'error',
    //         mensagem:'Digite o nome do leiturista!'
    //     });
    //     if(!leituristas.codigo)return setStatus({
    //         type:'error',
    //         mensagem:'Digite o código!'
    //     });
    //     return true;
    // }

    async function validate() {
        let schema = yup.object().shape({
            codigo: yup.string("Erro: Digite o codigo")
                .required("Erro: Digite o codigo"),
            name: yup.string("Erro: Digite o nome")
                .required("Erro: Digite o nome")
        });
        try {
            await schema.validate({
                name: leituristas.name,
                codigo: leituristas.codigo
            });
            return true;
        } catch (err) {
            setStatus({
                type: 'error',
                mensagem: err.errors
            });
            return false;
        }

    }

    return (
        <>
            <Menu />
            <Container className='boxListar'>
                <h1>Adicionar Leituristas</h1>
                {status.type === 'success' ? <Navigate to="/leituristas" state={mensagemAdd} /> : ""}
                {status.type === 'error' ? <p style={{ color: "red" }}>{status.mensagem}</p> : ""}
                <Form onSubmit={addLeituristas}>
                    <Form.Group className="mb-3">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Digite seu nome" onChange={valueInput}></Form.Control><br />

                        <Form.Label>Código</Form.Label>
                        <Form.Control type="text" name="codigo" placeholder="digite o codigo" onChange={valueInput}></Form.Control><br />
                    </Form.Group>
                    <div className="gap-3">
                        <Button type="submit">Cadastrar</Button>
                    </div>
                </Form>
            </Container>
        </>
    );
};