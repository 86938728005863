import React, { useEffect, useState } from 'react';
import { Menu } from '../../components/Menu';
import { Container } from 'react-bootstrap';

import api from '../../config/configAPi';

export const Dashboard = () => {
  const [countUsers, setContUsers] = useState('');
  const [countLeitu, setContLeitu] = useState('');
  const [countArquivos, setContArquivo] = useState('');
  const [countArqBaixa, setcountArqBaixa] = useState(0);

  const getUsers = async () => {
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    await api.get('/users', headers).then((response) => {
      //console.log(response.data.users.length);
      setContUsers(response.data.users.length);
    });
  };

  const getLeituristas = async () => {
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    await api.get('/leituristas', headers).then((response) => {
      //console.log(response.data.leituristas.length);
      setContLeitu(response.data.leituristas.length);
    });
  };

  const getArquivosRecad = async () => {
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    await api.get('/recad_controles', headers).then((response) => {
      //console.log(response.data.recadControle.length);
      setContArquivo(response.data.recadControle.length);
    });
  };

  const getArquivosBaixaRecad = async () => {
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };
    await api.get('/recad_arquivosBaixa', headers).then((response) => {
      setcountArqBaixa(response.data.quantidade);
    });
  };

  const AtualizaDados = () => {
    getUsers();
    getLeituristas();
    getArquivosRecad();
    getArquivosBaixaRecad();
  };
  AtualizaDados();

  useEffect(() => {
    const intervalId = setInterval(() => {
      AtualizaDados();
    }, 120000);
    return () => clearInterval(intervalId);
  });

  return (
    <>
      <Menu />
      <Container className="boxListar">
        <div className="row">
          <div className="col-sm-6">
            <div
              className="card"
              style={{
                backgroundColor: '#00BFFF',
                color: '#fff',
                height: '150px',
              }}
            >
              <div className="card-body">
                <h5 className="card-title">Usuarios</h5>
                <p className="card-text">Usuários Cadastrados: {countUsers}</p>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div
              className="card"
              style={{
                backgroundColor: '#87CEEB',
                color: '#fff',
                height: '150px',
              }}
            >
              <div className="card-body">
                <h5 className="card-title">Leituristas</h5>
                <p className="card-text">
                  Leituristas Cadastrados: {countLeitu}
                </p>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div className="row">
          <div className="col-sm-6">
            <div
              className="card"
              style={{
                backgroundColor: '#1E90FF',
                color: '#fff',
                height: '150px',
              }}
            >
              <div className="card-body">
                <h5 className="card-title">Arquivos</h5>
                <p className="card-text">
                  Arquivos Importados: {countArquivos}
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div
              className="card"
              style={{
                backgroundColor: '#4682B4',
                color: '#fff',
                height: '150px',
              }}
            >
              <div className="card-body">
                <h5 className="card-title">Arquivos prontos para baixa</h5>
                <p className="card-text">
                  Arquivos disponíveis: {countArqBaixa}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
