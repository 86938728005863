import React, { useState, useRef } from 'react';

import Swal from 'sweetalert2';

import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Menu } from '../../components/Menu';

import api from '../../config/configAPi';

export const Arquivo = () => {
  const [image, setImage] = useState('');

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const [nameArq, setNameArq] = useState('');
  const [NUM_ARQ, setArquivo] = useState('');
  const [ANO_ARQ, setAnoarq] = useState('');
  const [CICLO, setCiclo] = useState('');

  const displayNameRef = useRef();

  function nomearquivo(image) {
    var string1 = image.substring(6);
    var NUM_ARQ = string1.substring(0, 5);
    setArquivo(NUM_ARQ);
    var ANO_ARQ = string1.substring(5, 9);
    setAnoarq(ANO_ARQ);
    var CICLO = string1.substring(10, 13);
    setCiclo(CICLO);
    setNameArq(
      'Importando o Arquivo: ' + NUM_ARQ + ' Ano:' + ANO_ARQ + ' Ciclo:' + CICLO
    );
  }

  const uploadArquivo = async () => {
    //e.preventDefault();
    if (!validate()) return;

    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        filetoJson(reader.result);
      },
      false
    );

    reader.readAsText(image, 'ISO-8859-1');

    let results = [];
    let resultsLogradouros = [];

    async function filetoJson(reg) {
      var linhas = reg.split(/\r?\n/);

      linhas.forEach(function (linha) {
        //console.log(linha);
        if (linha.substring(0, 3).trim() === 'A20') {
          results = [
            ...results,
            {
              NUM_ARQ,
              ANO_ARQ,
              TIPO_REG: 1,
              CICLO,
              SITUACAO_REG: 0,
              COD_LIGACAO: +linha.substring(3, 9),
              DV_LIGACAO: +linha.substring(9, 10),
              NOME_PROPRIETARIO: linha.substring(10, 110).trim(),
              RG_PROP: linha.substring(110, 130).trim(),
              CPF_PROP: linha.substring(130, 144).trim(),
              CNPJ_PROP: linha.substring(144, 162).trim(),
              NOME_USUARIO: linha.substring(162, 262).trim(),
              RG: linha.substring(262, 282).trim(), //20
              CPF: linha.substring(282, 296).trim(), //14
              CNPJ: linha.substring(296, 314).trim(), //18
              TELEFONE_CELULAR: linha.substring(314, 328).trim(), //14
              //TELEFONE: linha.substring(330, 344).trim(), //14
              TELEFONE: linha.substring(328, 342).trim(), //14
              END_IMO: +linha.substring(342, 348), //6
              NUM_IMO: +linha.substring(348, 353), //6
              COMPLEMENTO: linha.substring(353, 368).trim(), //15
              GPS_LONGITUDE: linha.substring(368, 387).trim(), //19
              GPS_LATITUDE: linha.substring(387, 406).trim(), //19
              QUADRANTE_ZONA: linha.substring(406, 409).trim(), //3
              SUB_QUADRANTE_ZONA: +linha.substring(409, 413), //4 ------------------
              SETOR: +linha.substring(413, 416), //3
              QUADRA: linha.substring(416, 419), //3
              SEQUENCIA: linha.substring(419, 425), //6
              NUM_ECONO_RES: +linha.substring(425, 429), //4
              NUM_ECONO_COM: +linha.substring(429, 433), //4
              NUM_ECONO_IND: +linha.substring(433, 437), //4
              NUM_ECONO_PUB: +linha.substring(437, 441), //4
              NUM_ECONO_OUT: +linha.substring(441, 445), //4
              SUB_CATEG_DOM: +linha.substring(445, 448), //3
              SUB_CATEG_COM: +linha.substring(448, 451), //3
              SUB_CATEG_IND: +linha.substring(451, 454), //3
              SUB_CATEG_PUB: +linha.substring(454, 457), //3
              SUB_CATEG_OUT: +linha.substring(457, 460), //3
              LOGRADOURO: linha.substring(460, 569).trim(), //9 + 100
              NUM_HIDROMETRO: linha.substring(569, 581).trim(), //12
              TIPO_CLIENTE: +linha.substring(581, 583), //2
              TIPO_CLIENTE_PROP: +linha.substring(583, 585), //2
              LOCALIZACAO_HIDROMETRO: +linha.substring(585, 586), //1
            },
          ];
        } else if (linha.substring(0, 3).trim() === 'A10') {
          //COMENTADO PARA RESOLVER APÓS A ADEQUAÇÃO DO RECAD VIA PROG DIRETO NA API
          /*resultsLogradouros = [
            ...resultsLogradouros,
            {
              ID: +linha.substring(3, 9),
              LOGRADOURO: linha.substring(9, 43).trim(),
            },
          ];*/
        }
      });

      //console.log(results);
      //const regx = new RegExp(/\n/g,)
      //const regx = new RegExp(/^A(\d{2})(\d{6})(\d{1})(.*)/gmi);
      //(\w+ [a-zA-Z]?:?\w+?:?)
      //const regx = new RegExp(/(^A\d{2})(\d{6})(\d{1})(\w+ [a-zA-Z]?:?.a\w+ ?:? \w+?:?\w+?:?\w+?:?\w+?:?\w+\/?\/?:?\S+)/gmi);
      //  const regx = new RegExp(/(^A\d{2})(\d{6})(\d{1}) (\w+ [a-zA-Z]?:?.a\w+ ?:? \w+?:?\w+?:?\w+?:?\w+?:?\w+\/?\/?:?......................................................................          )([^a-zA-Z]...................)([^a-zA-Z]............)([^a-zA-Z]..................)(\w+ [a-zA-Z]?:?.a\w+ ?:? \w+?:?\w+?:?\w+?:?\w+?:?\w+\/?\/?:?.............................................................................)([^a-zA-Z]....................)([^a-zA-z]..............)([^a-zA-Z]..................)([^a-zA-Z]..............)([^a-zA-Z]..............)(\d{6})(\d{6})([\w+]..............)([\w+]..................)([\w+]..................) ([\w+]..)([\w+]...)([\w+]..)([\w+]..)([\w+].....)([\w+]...)([\w+]...)([\w+]...)([\w+]...)([\w+]...)([\w+]..)([\w+]..)([\w+]..)([\w+]..)([\w+]..)(.*) )/gmi);

      const headers = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      };

      await api
        .post('/recad_dados', results, headers)
        .then((response) => {
          setStatus({
            type: 'success',
            mensagem: response.data.mensagem,
          });
          //alert(response.data.mensagem);
          mostrarAlert('success', response.data.mensagem);

          setImage('');
        })
        .catch((err) => {
          if (err.response) {
            setStatus({
              type: 'error',
              mensagem: err.response.data.mensagem,
            });
            //alert(err.response.data.mensagem);
            mostrarAlert('error', err.response.data.mensagem);
          } else {
            setStatus({
              type: 'error',
              mensagem: err,
              //   //'Erro:Tente novamente!'
            });
            //alert(err);
            mostrarAlert('error', err);
          }
          setImage('');
        });

      //inserir os registros atualizados de LOGRADOUROS vindo do VB
      /*console.log('results log', resultsLogradouros);
      if (resultsLogradouros) {
        await api
          .post('/logradouros', resultsLogradouros, headers)
          .then((response) => {            
            setStatus({
              type: 'success',
              mensagem: response.data.mensagem,
            });

            return;
          })
          .catch((err) => {            
            if (err.response) {
              setStatus({
                type: 'error',
                mensagem: err.response.data.mensagem,
              });
            } else {
              setStatus({
                type: 'error',
                mensagem: err,
                //   //'Erro:Tente novamente!'
              });
            }

            return;
          });
      }*/
    }
  };

  function validate() {
    if (!image.text)
      return setStatus({
        type: 'error',
        mensagem: 'Arquivo não selecionado!',
      });
    return true;
  }

  const mostrarAlert = async (icon, mensagem) => {
    const result = await Swal.fire({
      icon: icon,
      title: mensagem,
    });
    if (result.isConfirmed) {
      setStatus({ type: '', mensagem: '' });
      setNameArq('');
      displayNameRef.current.value = '';
    }
  };

  // const uploadRegistroLogradouros = async () => {
  //   if (!validate()) return;

  //   const reader = new FileReader();
  //   reader.addEventListener(
  //     'load',
  //     () => {
  //       filetoJson(reader.result);
  //     },
  //     false
  //   );

  //   reader.readAsText(image, 'ISO-8859-1');
  //   let results = [];

  //   async function filetoJson(reg) {
  //     var linhas = reg.split(/\r?\n/);

  //     linhas.forEach(function (linha) {
  //       //console.log(linha);
  //       if (linha.substring(0, 3).trim() === 'A10') {
  //         results = [
  //           ...results,
  //           {
  //             ID: +linha.substring(3, 9),
  //             LOGRADOURO: linha.substring(9, 43).trim(),
  //           },
  //         ];
  //       }
  //     });

  //     //console.log(results);
  //     //const regx = new RegExp(/\n/g,)
  //     //const regx = new RegExp(/^A(\d{2})(\d{6})(\d{1})(.*)/gmi);
  //     //(\w+ [a-zA-Z]?:?\w+?:?)
  //     //const regx = new RegExp(/(^A\d{2})(\d{6})(\d{1})(\w+ [a-zA-Z]?:?.a\w+ ?:? \w+?:?\w+?:?\w+?:?\w+?:?\w+\/?\/?:?\S+)/gmi);
  //     //  const regx = new RegExp(/(^A\d{2})(\d{6})(\d{1}) (\w+ [a-zA-Z]?:?.a\w+ ?:? \w+?:?\w+?:?\w+?:?\w+?:?\w+\/?\/?:?......................................................................          )([^a-zA-Z]...................)([^a-zA-Z]............)([^a-zA-Z]..................)(\w+ [a-zA-Z]?:?.a\w+ ?:? \w+?:?\w+?:?\w+?:?\w+?:?\w+\/?\/?:?.............................................................................)([^a-zA-Z]....................)([^a-zA-z]..............)([^a-zA-Z]..................)([^a-zA-Z]..............)([^a-zA-Z]..............)(\d{6})(\d{6})([\w+]..............)([\w+]..................)([\w+]..................) ([\w+]..)([\w+]...)([\w+]..)([\w+]..)([\w+].....)([\w+]...)([\w+]...)([\w+]...)([\w+]...)([\w+]...)([\w+]..)([\w+]..)([\w+]..)([\w+]..)([\w+]..)(.*) )/gmi);

  //     const headers = {
  //       headers: {
  //         Authorization: 'Bearer ' + localStorage.getItem('token'),
  //       },
  //     };

  //     await api
  //       .post('/logradouros', results, headers)
  //       .then((response) => {
  //         setStatus({
  //           type: 'success',
  //           mensagem: response.data.mensagem,
  //         });
  //         //alert(response.data.mensagem);
  //         //mostrarAlert('success', response.data.mensagem);

  //         return;
  //       })
  //       .catch((err) => {
  //         if (err.response) {
  //           setStatus({
  //             type: 'error',
  //             mensagem: err.response.data.mensagem,
  //           });
  //           //alert(err.response.data.mensagem);
  //           //mostrarAlert('error', err.response.data.mensagem);
  //         } else {
  //           setStatus({
  //             type: 'error',
  //             mensagem: err,
  //             //   //'Erro:Tente novamente!'
  //           });
  //           //alert(err);
  //           //mostrarAlert('error', err);
  //         }

  //         return;
  //       });
  //   }
  // };

  const deleteTodosLogradouros = async () => {
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    await api
      .delete('/logradouros/todos', headers)
      .then((response) => {
        return;
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'erro',
            mensagem: err.response.data.mensagem,
          });
          return;
        } else {
          setStatus({
            type: 'erro',
            mensagem: 'Tente mais tarde!',
          });
          return;
        }
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      //Apaga todos os logradouros para inserir os registros atualizados vindo do VB
      //await deleteTodosLogradouros();
      await uploadArquivo();

      setStatus({
        type: 'success',
        mensagem: 'Operações concluídas com sucesso!',
      });
    } catch (err) {
      setStatus({
        type: 'erro',
        mensagem: 'Tente mais tarde!',
      });
    }
  };

  return (
    <>
      <Menu />
      <Container className="boxListar">
        <h2>Importa Arquivo Recad</h2>
        <br />
        {/* {status.type === 'success'
          ? //<p style={{ color: "green" }}>{status.mensagem}</p>
            ''
          : ''}
        {status.type === 'error'
          ? //<p style={{ color: "red" }}>{status.mensagem}</p>
            ''
          : //''
            ''} */}

        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Selecione o arquivo para importação</Form.Label>
            <Form.Control
              type="file"
              name="imagem"
              ref={displayNameRef}
              displayname={NUM_ARQ}
              onChange={(e) => {
                nomearquivo(e.target.files[0].name);
                setImage(e.target.files[0]);
              }}
            />
          </Form.Group>
          <p style={{ color: 'red', fontWeight: 'bold' }}>{nameArq}</p>
          <div className="gap-3">
            <Button type="submit">
              <i className="fa-solid fa-upload"></i> Importar
            </Button>
          </div>
          <br />
        </Form>
      </Container>
    </>
  );
};
