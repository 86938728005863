import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as yup from 'yup';

import { Menu } from '../../components/Menu';

import api from '../../config/configAPi';

export const EditUser = () => {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const editUser = async (e) => {
    e.preventDefault();
    //console.log(name);

    if (!(await validate())) return;

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    await api
      .put('/user', { id, name, email, password }, headers)
      .then((response) => {
        setStatus({
          type: 'success',
          mensagem: response.data.mensagem,
        });
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Tente mais tarde!',
          });
        }
      });
  };

  useEffect(() => {
    const getUser = async () => {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      };

      await api
        .get('user/' + id, headers)
        .then((response) => {
          if (response.data.user) {
            //console.log(response.data.user);
            setName(response.data.user.name);
            setEmail(response.data.user.email);
          } else {
            setStatus({
              type: 'error',
              mensagem: 'Erro: Usuário não encontrado!',
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            setStatus({
              type: 'error',
              mensagem: err.response.data.mensagem,
            });
          } else {
            setStatus({
              type: 'error',
              mensagem: 'Erro: Tente mais tarde!',
            });
          }
        });
    };
    getUser();
  }, [id]);

  async function validate() {
    let schema = yup.object().shape({
      password: yup
        .string('Erro: Digite a senha')
        .required('Erro: Digite a senha')
        .min(6, 'Erro: A senha deve ter no minimo 6 caracteres'),
      email: yup
        .string('Erro: Digite o email')
        .required('Erro: Digite o email'),
      name: yup.string('Erro: Digite o nome').required('Erro: Digite o nome'),
    });
    try {
      await schema.validate({
        name,
        email,
        password,
      });
      return true;
    } catch (err) {
      setStatus({
        type: 'error',
        mensagem: err.errors,
      });
      return false;
    }
  }

  return (
    <>
      <Menu />
      <Container className="boxListar">
        <h2>Editar usuário</h2>
        <br />
        <br />

        {/* {status.type==='error'?
            <Redirect to={{
                pathname: '/users',
                state:{
                    type: status.type,
                    mensagem:status.mensagem
                }
            }}/>: ""} */}

        {status.type === 'success' ? (
          <p style={{ color: 'green' }}>{status.mensagem}</p>
        ) : (
          ''
        )}
        {status.type === 'error' ? (
          <p style={{ color: 'red' }}>{status.mensagem}</p>
        ) : (
          ''
        )}

        <Form onSubmit={editUser}>
          <Form.Group className="mb-3">
            <Form.Label>Nome:</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Nome completo do usuario"
              value={name}
              onChange={(text) => setName(text.target.value)}
            />
            <br />

            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Digite seu email de acesso"
              value={email}
              onChange={(text) => setEmail(text.target.value)}
            />
            <br />

            <Form.Label>Senha:</Form.Label>
            <Form.Control
              ut
              type="password"
              name="password"
              placeholder="Digite a senha"
              autoComplete="on"
              onChange={(text) => setPassword(text.target.value)}
            />
            <br />
          </Form.Group>
          <div className="gap-3">
            <Button type="submit">
              <i className="fa-solid fa-floppy-disk-circle-arrow-right"></i>
              Salvar
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
};
