import React, { useContext } from "react";
import {Routes, Route, Navigate} from 'react-router-dom'

import {Login} from '../pages/Login';
import {Dashboard} from "../pages/Dashboard";
import {Users} from '../pages/Users';
import {Leituristas} from '../pages/Leituristas';
import {AddUser} from '../pages/AddUser';
import {AddLeituristas} from '../pages/AddLeiturista'
import {EditLeituristas} from '../pages/EditLeituristas';
import {EditUser} from '../pages/EditUser';
import {Arquivo} from '../pages/Arquivo';
import {GeraArquivo} from '../pages/GeraArquivo';

import { Context } from "../Context/AuthContext";

function CustomRoute({children, RedirectTo}){
    const { authenticated } = useContext(Context);
    return authenticated ? children :<Navigate to={RedirectTo} />
}

export default function RoutesAdm(){
    return(
        <Routes>        
          <Route exact path="/" element={<Login />}/>
          <Route exact isPrivate path="/dashboard" element={<CustomRoute RedirectTo="/"><Dashboard /></CustomRoute>}/>
          <Route exact isPrivate path="/users" element={<CustomRoute RedirectTo="/"><Users /></CustomRoute>}/>
          <Route exact isPrivate path="/leituristas" element={<CustomRoute RedirectTo="/"><Leituristas /></CustomRoute>}/>
          <Route exact isPrivate path="/add-user" element={<CustomRoute RedirectTo="/"><AddUser /></CustomRoute>}/>
          <Route exact isPrivate path="/add-leituristas" element={<CustomRoute RedirectTo="/"><AddLeituristas /></CustomRoute>}/>
          <Route exact isPrivate path="/edit-leituristas/:id" element={<CustomRoute RedirectTo="/"><EditLeituristas /></CustomRoute>}/>
          <Route exact isPrivate path="/edit-user/:id" element={<CustomRoute RedirectTo="/"><EditUser /></CustomRoute>}/>
          <Route exact isPrivate path="/Arquivo" element={<CustomRoute RedirectTo="/"><Arquivo /></CustomRoute>}/>
          <Route exact isPrivate path="/GeraArquivo" element={<CustomRoute RedirectTo="/"><GeraArquivo /></CustomRoute>}/>

      </Routes>      
    );
};