import React, { useEffect, useState } from 'react';

import { Container } from 'react-bootstrap';
import { Menu } from '../../components/Menu';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import api from '../../config/configAPi';

export const Leituristas = () => {
  const [data, setData] = useState([]);

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });

  const getLeituristas = async () => {
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    await api
      .get('/leituristas', headers)
      .then((response) => {
        //console.log(response);
        setData(response.data.leituristas);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'erro',
            mensagem: err.response.data.mensagem,
          });
          //console.log(err.response.data.mensagem);
        } else {
          //console.log("erro:Tente mais tarde");
          setStatus({
            type: 'erro',
            mensagem: 'Tente mais tarde',
          });
        }
      });
  };
  useEffect(() => {
    getLeituristas();
  }, []);

  const deleteLeiturista = async (idLeiturista) => {
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    await api
      .delete('/leiturista/' + idLeiturista, headers)
      .then((response) => {
        //console.log(response.data.mensagem);
        setStatus({
          type: 'success',
          mensagem: response.data.mensagem,
        });
        getLeituristas();
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'erro',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'erro',
            mensagem: 'Tente mais tarde!',
          });
        }
      });
  };

  return (
    <>
      <Menu />
      <Container>
        <br />
        <br />
        <h2>Leituristas Cadastrados</h2>
        <br />
        <br />
        <Link to="/add-leituristas">
          <Button type="button" variant="primary">
            <i className="fa-solid fa-user" style={{ fontSize: '20px' }}></i>{' '}
            Cadastrar
          </Button>
        </Link>

        {status.type === 'success' ? (
          <p style={{ color: 'green' }}>{status.mensagem}</p>
        ) : (
          ''
        )}
        {status.type === 'error' ? (
          <p style={{ color: 'red' }}>{status.mensagem}</p>
        ) : (
          ''
        )}

        <Table
          striped
          bordered
          hover
          style={{ marginTop: '15px' }}
          className="boxListar"
        >
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Código</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => {
              return [
                <tr key={i}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.codigo}</td>
                  <td>
                    <Link to={'/edit-leituristas/' + item.id}>
                      <Button variant="success" type="button">
                        <i
                          className="fa-solid fa-pen-to-square"
                          style={{ fontSize: '20px' }}
                        ></i>{' '}
                        Editar
                      </Button>
                    </Link>{' '}
                    <Link to={'#'}>
                      <Button
                        variant="danger"
                        type="button"
                        onClick={() => deleteLeiturista(item.id)}
                      >
                        <i
                          className="fa-solid fa-trash"
                          style={{ fontSize: '20px' }}
                        ></i>{' '}
                        Excluir
                      </Button>
                    </Link>
                  </td>
                </tr>,
              ];
            })}
          </tbody>
        </Table>
      </Container>
    </>
  );
};
