import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as yup from 'yup';
import { Menu } from '../../components/Menu';
import { Container } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import api from '../../config/configAPi';

export const EditLeituristas = () => {

    const { id } = useParams();
    const [name, setName] = useState('');
    const [codigo, setCodigo] = useState('');

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    })

    const editLeituristas = async e => {
        e.preventDefault();
        //console.log(name);

        if (!(await validate())) return;

        const headers = {
            'headers': {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }

        await api.put('/leiturista', { id, name, codigo }, headers)
            .then((response) => {
                setStatus({
                    type: 'success',
                    mensagem: response.data.mensagem
                });
            }).catch((err) => {
                if (err.response) {
                    setStatus({
                        type: 'error',
                        mensagem: err.response.data.mensagem
                    });
                } else {
                    setStatus({
                        type: 'error',
                        mensagem: "Tente mais tarde!"
                    });
                }
            })
    }

    useEffect(() => {
        const getUser = async () => {
            const headers = {
                'headers': {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }

            await api.get("leituristas/" + id, headers)
                .then((response) => {
                    if (response.data.leituristas) {
                        //console.log(response.data.leituristas);
                        setName(response.data.leituristas.name);
                        setCodigo(response.data.leituristas.codigo);

                    } else {
                        setStatus({
                            type: 'error',
                            mensagem: "Erro: Usuário não encontrado!"
                        });
                    }
                }).catch((err) => {
                    if (err.response) {
                        setStatus({
                            type: 'error',
                            mensagem: err.response.data.mensagem
                        })
                    } else {
                        setStatus({
                            type: 'error',
                            mensagem: "Erro: Tente mais tarde!"
                        })
                    }
                })

        }
        getUser();

    }, [id]);
    // function validate(){
    //     if(!name)return setStatus({
    //         type:'error',
    //         mensagem:'Digite o nome do leiturista!'
    //     });
    //     if(!codigo)return setStatus({
    //         type:'error',
    //         mensagem:'Digite o código!'
    //     });


    //     return true;
    // }
    async function validate() {
        let schema = yup.object().shape({
            codigo: yup.string("Erro: Digite o codigo")
                .required("Erro: Digite o codigo"),
            name: yup.string("Erro: Digite o nome")
                .required("Erro: Digite o nome")
        });
        try {
            await schema.validate({
                name,
                codigo
            });
            return true;
        } catch (err) {
            setStatus({
                type: 'error',
                mensagem: err.errors
            });
            return false;
        }

    }
    return (
        <>
            <Menu />
            <Container className='boxListar'>
                <h2>Editar leiturista</h2><br />
                {/* {status.type==='error'?
            <Redirect to={{
                pathname: '/users',
                state:{
                    type: status.type,
                    mensagem:status.mensagem
                }
            }}/>: ""} */}

                {status.type === 'error' ? <p style={{ color: "red" }}>{status.mensagem}</p> : ""}
                {status.type === 'success' ? <p style={{ color: "green" }} >{status.mensagem}</p> : ""}


                <Form onSubmit={editLeituristas}>
                    <Form.Group className="mb-3">
                        <Form.Label>Nome:</Form.Label>
                        <Form.Control type="text" name="name" placeholder="Nome do leiturista" value={name}
                            onChange={text => setName(text.target.value)} /><br />

                        <Form.Label>Codigo:</Form.Label>
                        <Form.Control type="text" name="codigo" placeholder="Digite o codigo" value={codigo}
                            onChange={text => setCodigo(text.target.value)} /><br />
                    </Form.Group>
                    <div className="gap-3">
                        <Button type="submit">Salvar</Button>
                    </div>
                </Form>
            </Container>
        </>
    );
};