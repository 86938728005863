import React, { useState } from 'react';

import Swal from 'sweetalert2';

import { Container } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Menu } from '../../components/Menu';

import api from '../../config/configAPi';

export const GeraArquivo = () => {
  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });
  const baseUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://apitocantins.gestcominformatica.com.br'
      : 'http://localhost:3005';

  const [urlDownload, setUrlDownload] = useState(
    `${baseUrl}/arquivo/${localStorage.getItem('token')}`
  );

  const geraarquivo = async (e) => {
    e.preventDefault();
    const headers = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    };

    api
      .get('/arquivo', headers)
      .then((response) => {
        setStatus({
          type: 'success',
          mensagem: response.data.mensagem,
        });
        mostrarAlert('success', response.data.mensagem);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
          mostrarAlert('error', err.response.data.mensagem);
        } else {
          setStatus({
            type: 'error',
            mensagem: err,
            //'Erro:Tente novamente!'
          });
          mostrarAlert('error', err);
        }
      });
  };

  const mostrarAlert = async (icon, mensagem) => {
    const result = await Swal.fire({
      icon: icon,
      title: mensagem,
    });
    if (result.isConfirmed) {
      setStatus({ type: '', mensagem: '' });
    }
  };

  return (
    <>
      <Menu />
      <Container className="boxListar">
        <h2>Baixar arquivo</h2>
        <br />
        {status.type === 'success' ? (
          <p style={{ color: 'green' }}>{status.mensagem}</p>
        ) : (
          ''
        )}
        {status.type === 'error' ? (
          <p style={{ color: 'red' }}>{status.mensagem}</p>
        ) : (
          ''
        )}

        <Form onSubmit={geraarquivo}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Clique no botão abaixo para gerar o arquivo</Form.Label>
          </Form.Group>
          <div className="gap-3">
            <a
              href={urlDownload}
              target="_blank"
              rel="noreferrer"
              className="botaoImport"
            >
              <i className="fa-solid fa-download"></i> Gerar Arquivo
            </a>
          </div>
          <br />
        </Form>
      </Container>
    </>
  );
};
